"use strict";

jQuery(document).ready(function (jQuery) {
  jQuery(document).foundation();
  var body = jQuery("body");
  var nav = jQuery(".nav-container");
  jQuery('button#openMenu').click(function () {
    nav.addClass("open");
    body.addClass("open");
  });
  jQuery('button#close-menu').click(function () {
    nav.removeClass("open");
  });
  var ul = jQuery("nav#site-navigation ul li ul");
  var li = jQuery("nav#site-navigation ul li ul li");

  if (ul.hasClass("children")) {
    ul.parent(li).addClass("hasChildren");
  }

  if (li.hasClass("hasChildren")) {
    jQuery("li.hasChildren a").append("<a href='javascript:void(0)' class='expandChild' title='Child item'></a>");
  }

  jQuery(document).mouseup(function (e) {
    if (!nav.is(e.target) // if the target of the click isn't the container...
    && nav.has(e.target).length === 0) // ... nor a descendant of the container
      {
        body.removeClass("open");
        nav.removeClass('open');
      }
  });
  jQuery(document).ready(function () {
    jQuery('.open-search-from').click(function () {
      jQuery('section').addClass("open-search");
      jQuery('body').addClass("search-main");
    });
    jQuery('#deleteSearch').click(function () {
      jQuery('section').removeClass("open-search");
      jQuery('body').removeClass("search-main");
    });
  });
  jQuery(window).on('scroll touchmove', function () {
    jQuery('.fade-in').each(function () {
      if (isScrolledIntoView(jQuery(this))) {
        jQuery(this).addClass('animation-started');
      }
    });
  });
  var didScroll;
  var lastScrollTop = 0;
  var delta = 5;
  var navbarHeight = jQuery('header').outerHeight();
  jQuery(window).scroll(function (event) {
    didScroll = true;
  });
  setInterval(function () {
    if (didScroll) {
      hasScrolled();
      didScroll = false;
    }
  }, 250);

  function hasScrolled() {
    var st = jQuery(window).scrollTop(); // Make sure they scroll more than delta

    if (Math.abs(lastScrollTop - st) <= delta) return; // If they scrolled down and are past the navbar, add class .nav-up.
    // This is necessary so you never see what is "behind" the navbar.

    if (st > lastScrollTop && st > navbarHeight) {
      // Scroll Down
      jQuery('header').removeClass('nav-down').addClass('nav-up');
    } else {
      // Scroll Up
      if (st + jQuery(window).height() < jQuery(document).height()) {
        jQuery('header').removeClass('nav-up').addClass('nav-down');
      }
    }

    lastScrollTop = st;
  }

  var formTab = jQuery("#gform_target_page_number_3").val();

  if (formTab === '2') {
    jQuery('.gform_previous_button').hide();
    jQuery('#submitForm').hide();
    jQuery('.firstTabTitle').show();
    jQuery('.secondTabTitle').hide();
    jQuery('.module-footer ul').hide();
  } else {
    jQuery('.gform_next_button').hide();
    jQuery('.gform_previous_button').show();
    jQuery('.firstTabTitle').hide();
    jQuery('.secondTabTitle').show();
    jQuery('.module-footer ul').show();
  }

  jQuery('#submitForm').click(function () {
    jQuery('#gform_submit_button_3').click();
  });

  if (!Cookies.get('options')) {
    var cookieOptionArray = [];
    var package_id = jQuery('#package-options').data('package-id');
    Cookies.set('options', JSON.stringify(cookieOptionArray));
    Cookies.set('options_package_id', package_id);
  }

  var optionArray = [];

  if (Cookies.get('options')) {
    var cookies = Cookies.get('options');
    var cookieArray = cookies.split(',');
    setTimeout(function () {
      jQuery('#input_3_27').val(0);
      $('#input_3_4').val(cookies);
      $.each(cookieArray, function (key, val) {
        $(".option-cards article[data-id='" + val + "']").click();
      });
    }, 10);
  }

  var optionList = $("ul.added-options");
  optionList.on("click", "li", function () {
    var id = jQuery(this).data('option-id');
    $(this).remove();
    cookieArray.splice(cookieArray.indexOf(id), 1);
    var dateString = cookieArray.join();
    Cookies.set('options', dateString);
    jQuery(".option-cards article[data-id='" + id + "']").click();
  });
  jQuery('.tabs-panel.is-active').each(function () {
    var allOptions = $('article.is-selected').length;
    $('.extras').hide();

    if (allOptions !== 0) {
      $('.extras').show();
    }

    jQuery('.option-cards article').each(function () {
      var toggle = true;
      jQuery(this).click(function () {
        var title = jQuery(this).find('.title').text();
        var cat = jQuery(this).data('cat');
        var id = jQuery(this).data('id');
        jQuery(this).toggleClass("is-selected");
        jQuery(this).toggleClass("is-selected-" + cat);
        var allOptions = $('article.is-selected').length;

        if (allOptions !== 0) {
          $('.extras').show();
        } else {
          $('.extras').hide();
        }

        var counter = $('article.is-selected-' + cat).length;
        var counterSpan = $('.' + cat + '-option-counter');
        counterSpan.text(counter);

        if (counter === 0) {
          counterSpan.hide();
        } else {
          counterSpan.css('display', 'flex');
        }

        if (toggle) {
          if (optionArray.indexOf(id) === -1) {
            optionArray.push(id);
            var dateString = optionArray.join();
            jQuery('#optionsArray').val(dateString);
            Cookies.set('options', dateString);
          }

          jQuery('.tabs-title.is-active').find('.option-counter').val(1);
          jQuery(".added-options").append('<li data-option-id="' + id + '"><i class="far fa-trash-alt"></i>' + title + '</li>');
          toggle = false;
        } else {
          optionArray.splice(optionArray.indexOf(id), 1);
          var dateString = optionArray.join();
          jQuery('#optionsArray').val(dateString);
          Cookies.set('options', dateString);
          jQuery(".added-options li[data-option-id='" + id + "']").remove();
          toggle = true;
        }
      });
    });
  });
  var oldDateValue = jQuery("#input_3_6").val();

  if (oldDateValue) {
    $('#formDate').text(oldDateValue);
    $('#formDateContainer').show();
    $('.form-information').show();
  } // Datepicker


  jQuery("#input_3_6").change(function () {
    var value = $(this).val();

    if (value) {
      $('#formDate').text(value);
      $('#formDateContainer').show();
      $('.form-information').show();
    }
  }); // Time

  var oldTimeValue = jQuery("#input_3_25").val();

  if (oldTimeValue) {
    $('#formTime').text(oldTimeValue);
    $('#formTimeContainer').show();
  }

  jQuery("#input_3_25").change(function () {
    var value = $(this).val();

    if (value) {
      $('#formTime').text(value);
      $('#formTimeContainer').show();
    }
  }); // Location

  var oldLocationValue = jQuery("#input_3_8").val();

  if (oldLocationValue) {
    $('#formAdres').text(oldLocationValue);
    $('#formAdresContainer').show();
  }

  jQuery("#input_3_8").change(function () {
    var value = $(this).val();

    if (value) {
      $('#formAdres').text(value);
      $('#formAdresContainer').show();
    }
  }); // Postal code

  var oldPostalValue = jQuery("#input_3_9").val();

  if (oldPostalValue) {
    $('#formPostalcode').text(', ' + oldPostalValue);
  }

  jQuery("#input_3_9").change(function () {
    var value = $(this).val();

    if (value) {
      $('#formPostalcode').text(', ' + value);
    }
  }); // Location

  var oldPersonsValue = jQuery("#input_3_12").val();

  if (oldPersonsValue) {
    $('#formPersons').text(oldPersonsValue);
    $('#formPersonContainer').show();
  }

  jQuery("#input_3_12").change(function () {
    var value = $(this).val();

    if (value) {
      $('#formPersons').text(value);
      $('#formPersonContainer').show();
    }
  });
  var oldTruckValue = $('input[name="input_26"]:checked').val();

  if (oldTruckValue) {
    $('#formFoodtruck').text(oldTruckValue);
    $('#formFoodtruckContainer').show();
  }

  jQuery("#input_3_26").change(function () {
    var value = $('input[name="input_26"]:checked').val();

    if (value) {
      $('#formFoodtruck').text(value);
      $('#formFoodtruckContainer').show();
    }
  });

  if ($(".module-submit")[0]) {
    Cookies.remove('options');
  }
});
jQuery.event.special.touchstart = {
  setup: function setup(_, ns, handle) {
    this.addEventListener('touchstart', handle, {
      passive: !ns.includes('noPreventDefault')
    });
  }
};
jQuery.event.special.touchmove = {
  setup: function setup(_, ns, handle) {
    this.addEventListener('touchmove', handle, {
      passive: !ns.includes('noPreventDefault')
    });
  }
};

function isScrolledIntoView(elem) {
  var docViewTop = jQuery(window).scrollTop();
  var docViewBottom = docViewTop + (jQuery(window).height() + 200); // 200px offset for earlier response

  var elemTop = jQuery(elem).offset().top;
  var elemBottom = elemTop + jQuery(elem).height();
  return elemBottom <= docViewBottom && elemTop >= docViewTop;
}

document.addEventListener("DOMContentLoaded", function () {
  function toggleMenu(target, icon) {
    if (target.style.display === 'none' || target.style.display === '') {
      target.style.display = 'block';
      icon.classList.add('active');
    } else {
      target.style.display = 'none';
      icon.classList.remove('active');
    }
  }

  function applyToggleMenu() {
    var screenWidth = window.innerWidth;
    var headers = document.querySelectorAll('h6[data-target]');
    headers.forEach(function (header) {
      var button = header.querySelector('button.toggle-menu');
      var targetSelector = header.getAttribute('data-target');
      var target = document.querySelector(targetSelector);
      var icon = button.querySelector('i');
      header.removeEventListener('click', function () {});
      button.removeEventListener('click', function () {});

      if (screenWidth <= 991) {
        header.addEventListener('click', function () {
          toggleMenu(target, icon);
        });
        button.addEventListener('click', function (event) {
          event.stopPropagation(); // Prevent event from bubbling up to the <h6> tag

          toggleMenu(target, icon);
        });
      } else {
        target.style.display = '';
        icon.classList.remove('active');
      }
    });
  }

  applyToggleMenu();
  window.addEventListener('resize', function () {
    applyToggleMenu();
  });
});